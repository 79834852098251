import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { ellipsis } from 'polished';

import Layout from '../components/Layout.jsx';
import SEO from '../components/Seo.jsx';

import { color } from '../theme';

const NextPageFooter = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-top: 3em;
`;

const PageElem = styled(Link)`
  padding: 0.4em;
  margin: 0.2em;
  color: ${color()};
  border: 1px ${color()} solid;
  border-radius: 2px;
  text-decoration: none;
  line-height: 2.5em;

  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: ${color()};
    color: white;
  }
`;

const TOC = styled.div`
  position: fixed;
  top: 100px;
  right: 20px;

  padding-left: 20px;

  font-size: small;
  max-width: 15em;

  border-left: 1px lightgrey solid;

  & a {
    margin: 2px;
    text-decoration: none;
    color: grey;
    font-weight: 200;
    ${ellipsis()};
  }

  & a.selected {
    cursor: default;
    font-weight: 500;
  }

  & p {
    margin: 0px;
  }

  & ul {
    padding-left: 0.5em;
    list-style: none;
  }

  @media (max-width: 1040px) {
    display: none;
  }

  @media (min-width: 1140px) {
    max-width: 20em;
  }

  @media (min-width: 1240px) {
    max-width: 30em;
  }
`;

const GlobalTOC = styled(TOC)`
  border-style: none;
  left: 20px;
  right: none;

  font-size: 0.9em;

  & a {
    color: #444;
  }
`;

class DocumentTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const docs = this.props.data.allMarkdownRemark.edges;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle} lang={post.fields.lang}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <h1>{post.frontmatter.title}</h1>

        <GlobalTOC>
          <ul>
            {docs
              .map(({ node: { fields: { slug, lang }, frontmatter: { title } } }) => ({
                slug,
                lang,
                title,
              }))
              .filter(({ lang }) => lang === post.fields.lang)
              .map(({ slug, title }) => (
                <li key={slug}>
                  <p>
                    <Link to={slug} className={slug === post.fields.slug ? 'selected' : ''}>
                      {title}
                    </Link>
                  </p>
                </li>
              ))}
          </ul>
        </GlobalTOC>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <TOC dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />

        <NextPageFooter>
          <li>
            {previous && (
              <PageElem to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </PageElem>
            )}
          </li>
          <li>
            {next && (
              <PageElem to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </PageElem>
            )}
          </li>
        </NextPageFooter>
      </Layout>
    );
  }
}

export default DocumentTemplate;

export const pageQuery = graphql`
  query DocBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
        lang
      }
      tableOfContents
      frontmatter {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
      edges {
        node {
          fields {
            slug
            lang
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
